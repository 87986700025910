import React  from "react";
import {Text,Heading,Box, SimpleGrid} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import Link from '../components/gatsbylink'
import {motion} from 'framer-motion'
import MySeo from '../components/seo' 

export default function Herzberg(){
    return(
        <motion.div 
        initial={{opacity:0,paddingBottom:"100px"}}
        animate={{
          opacity:1
        }}
        transition={{duration:.1,delay:.1,ease:"circInOut"}}
        >   
        <MySeo title="Antonie Herzberg" />
        <Box  mt="60px"><Link to="/bacharach"><Text> &rarr; back</Text></Link></Box>
        <SimpleGrid mt="60px" gap={4} columns={[1,1,2]}>
        <Box>
            <Heading fontSize="lg" py="8">Antonie Herzberg (1862–1942)</Heading>
            
            <Text variant="solid">
            Antonie Herzberg wurde am 13. April 1862 in Köln geboren. Sie entstammte einer wohlhabenden Kölner Kaufmannsfamilie und hatte vier Geschwister. Ihre Eltern waren Herz Herzberg und Juliane Herzberg, geb. Kaufmann. Der bekannte Dermatologe und Sexualwissenschaftler Max Marcuse war verwandt mit ihr. Ein Neffe von Antonie, Walter Herzberg, wurde als deutscher Grafiker und Karikaturist bekannt, er starb 1943 in Auschwitz. Dessen Frau verstarb in Theresienstadt. Walter Herzbergs Sohn Klaus konnte rechtzeitig nach Palästina emigrieren. Eine seiner Nachfahrinnen ist heute Professorin für Kabbalistik an der Universität Haifa.
            </Text>
           
            <Text variant="solid">
            Über das Leben von Antonie Herzberg ist nur wenig bekannt. Sie blieb ledig. Als ältere Frau zog sie nach Bacharach, möglicherweise um in Ruhe ihren Lebensabend zu verbringen, und wohnte dort unter verschiedenen Adressen. Bis 1937 wohnte sie in der Langstraße 54, dem Haus der Kaufleute Halle. Zuletzt bewohnte sie eine Mansarde in der Bauerstraße 5, wo sie sich etwas Geld als Handarbeitslehrerin verdiente. Sie wurde vermutlich im Jahr 1942 gezwungen, in ein sogenanntes Judenhaus in der Rosenstraße 14 zu ziehen, eine Sammelstelle vor der endgültigen Deportation in den Osten. Das Haus gehörte der Winzergenossenschaft Medenscheid. Es war in schlechtem baulichem Zustand und hatte bereits eine Zeitlang leer gestanden.
            </Text>
            <Text variant="solid">
            Am 27. Juli 1942 wurde Antonie Herzberg mit einem Transport vom Bacharacher Bahnhof nach Köln gebracht. Sie war an diesem Tag Teil eines Deportationszuges, der 1165 Menschen aus dem Rheinland in das Ghetto Theresienstadt in der besetzten Tschechoslowakei verschleppte. Karl Heinz Klumb aus Wachtberg erinnert sich 1994 in einem Brief an den damaligen Bürgermeister Mießner: „Ich dürfte einer der letzten Bacharacher gewesen sein, der diese Damen noch lebend gesehen hat. Ich war damals Schüler der Oberschule für Jungen in St. Goarshausen. Es muß in der Quarta gewesen sein, als wir mittags von St. Goar nach Hause fuhren, hatten wir ganz merkwürdige Wagen am hinteren Teil des Zuges gesehen. Sämtliche Türen dieser Abteilwagen waren mit SS-Männern besetzt. In Bacharach sah ich, daß zwei oder drei Personen unter SS-Bewachung auf dem Bahnsteig standen, die dann in diesen Wagen geschoben wurden. Im letzten Augenblick erkannte ich noch die Damen, von denen eine m. E. Hertzberg hieß, an die anderen Namen kann ich mich nicht mehr erinnern. Als ich dieses Erlebnis zu Hause erzählte, sagte meine Mutter nur: „Ach, dann haben sie die letzten Juden jetzt auch nach Theresienstadt geschafft.“ Dieser Satz ist mir nie aus dem Gedächtnis gegangen, und jedesmal, wenn ich in Bacharach durch die Rosenstraße gehe, muß ich daran denken.“ Antonie Herzberg verblieb in Theresienstadt beinahe sieben Wochen, bis sie am 19. September 1942 nach Treblinka im besetzten Polen abtransportiert wurde. Es ist anzunehmen, dass die 80-Jährige noch am Tag ihrer Ankunft getötet wurde. Für Antonie Herzberg wurde 2014 ein Stolperstein in der Bauerstraße 5/Ecke Langstraße verlegt.
            </Text>
            
           
            </Box>
            <Box  my="9">
            <Box pt={["1","1","16"]}>
                    <StaticImage src="../images/bacharach/11.jpg" alt="Antonie Herzberg"/>
                    <Text variant="Bild">Antonie Herzberg </Text>
                </Box>
               
                
            </Box>
            </SimpleGrid>
            </motion.div>
            
    )
}